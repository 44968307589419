import {accRestClient} from "../index";


const loadUsers = async (event) => {
  const response = await accRestClient.accountsApi().getUsers();
  return response.data;
};

const accountRestService = {
  loadUsers
};

export default accountRestService;