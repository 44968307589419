import {
    Button, Card,
    Result,
} from "antd";


function AccessDeniedPage() {
  return (
    <Card>
        <Result
            status="404"
            title="404"
            subTitle="Sorry, page not found."
        />
    </Card>
  );
}

export default AccessDeniedPage;
