import {useAuth} from "react-oidc-context";
import Title from "antd/es/skeleton/Title";
import PropTypes from "prop-types";

function OIDCLogin(props: React.PropsWithChildren<OIDCLoginProps>) {
    const auth = useAuth();
    
    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return (
            <div>
                <Title>Oops ...</Title>
                <Title level={2}>{auth.error.message}</Title>
            </div>
        )
    }
    
    if (!auth.isAuthenticated && props.forceLoginRedirect) {
        auth.signinRedirect();
        
    } else{
        return props.children;
        
    }
}

interface OIDCLoginProps {
    children?: React.ReactNode,
    forceLoginRedirect: boolean
}

OIDCLogin.propTypes = {
    children: PropTypes.node.isRequired,
    forceLoginRedirect: PropTypes.bool.isRequired
}; 
export default OIDCLogin;