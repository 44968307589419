import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import accountRestService from "../../services/acc-rest.service";


const initialState = {
    users: null,
    isUserLoading: false,
}

export const loadUsers: any = createAsyncThunk(
    "app/loadUsers",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await accountRestService.loadUsers();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppState: (state, action) => {
            console.log("Action called setAppState")
            console.log(state)
            console.log(action.payload)
            state = {...state, ...action.payload};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadUsers.pending, (state) => {
                state.isUserLoading = true;
            })
            .addCase(loadUsers.fulfilled, (state, action) => {
                state.isUserLoading = false;
                state.users = action.payload;
            })
            .addCase(loadUsers.rejected, (state, action) => {
                state.isUserLoading = false;
            })
    },
});

export const {setAppState} = appSlice.actions;

export default appSlice.reducer;