/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import 'antd/dist/antd.variable.min.css';
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import {ConfigProvider} from "antd";
import AccessDeniedPage from "./pages/AccessDeniedPage";
import ProtectedRoute from "./navigation/Routes/ProtectedRoute";
import OIDCLogin from "./components/login/OIDCLogin";
import Members from "./pages/Members";
import PageNotFound from "./pages/PageNotFound";

ConfigProvider.config({
    theme: {
        primaryColor: '#755B48'
    },
});

function App() {
    console.log(window.location.pathname)
        return (
            <OIDCLogin forceLoginRedirect={true}>
                <div className="App">
                    <Switch>
                        <Route path="/sign-up" exact children={<ProtectedRoute element={<SignUp />}/>}/>
                        <Route path="/sign-in" exact children={<ProtectedRoute element={<SignIn/>}/>}/>
                        <Main>
                            <Switch>
                                <Route exact path="/members" children={<ProtectedRoute element={<Members/>}/>}/>
                                <Route exact path="/dashboard" children={<ProtectedRoute element={<Home/>}/>}/>
                                <Route exact path="/tables" children={<ProtectedRoute element={<Tables/>}/>}/>
                                <Route exact path="/billing" children={<ProtectedRoute element={<Billing/>}/>}/>
                                <Route exact path="/rtl" children={<ProtectedRoute element={<Rtl/>}/>}/>
                                <Route exact path="/profile" children={<ProtectedRoute element={<Profile/>}/>}/>
                                <Route exact path="/error" children={<AccessDeniedPage/>}/>
                                <Redirect exact from="/" to="/members"/>
                                <Route path="/" children={<PageNotFound/>}/>
                            </Switch>
                        </Main>
                    </Switch>
                </div>
            </OIDCLogin>
        );
}

export default App;
