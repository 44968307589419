import { useAuth } from "react-oidc-context"
import {getAuthenticatedUser} from "../../utils/authHelper";
import {Redirect} from "react-router-dom";
import OIDCLogin from "../../components/login/OIDCLogin";
import PropTypes from "prop-types";

function ProtectedRoute({ element }) {
  const auth = useAuth();
  
  if(!auth.isAuthenticated || !getAuthenticatedUser().isAccountVerified() || !getAuthenticatedUser().isAdmin()) 
  {
    //return element
    return  <Redirect to={"/error"}/> 
  }
  else{
    return element     
  }
}

ProtectedRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default ProtectedRoute;
